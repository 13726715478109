
// Google Fonts
@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&amp;lang=en");

// Basic css
@import "imports.less";

// Variables, Mixins
@import "variables.less";
@import "mixins.less";

// INSPINIA Theme Elements
@import "typography.less";
@import "navigation.less";

@import "buttons.less";
@import "badgets_labels.less";
@import "elements.less";
@import "sidebar.less";
@import "base.less";
@import "pages.less";
@import "chat.less";
@import "metismenu.less";
@import "spinners.less";

// Landing page styles
@import "landing.less";

// RTL Support
@import "rtl.less";

// INSPINIA Skins
@import "skins.less";
@import "md-skin.less";

// Media query style
@import "media.less";

// Others
@import "oldcss.less";
@import "pfudor.less";
@import "components.less";

// Clear layout on print mode
@media print {
  @page {
    size: 297mm 210mm; /* landscape */
    /* you can also specify margins here: */
    margin: 25mm;
    margin-right: 45mm; /* for compatibility with both A4 and Letter */
  }

  nav.navbar-static-side {
    display: none;
  }

  #page-wrapper {
    margin: 0 !important;
  }



  body {
    -webkit-print-color-adjust: exact;
  }
}



