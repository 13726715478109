/*
 * Pfudor Edit START
 *
 */

/* Centering items on one row */
.v-center {
  display:table!important;
}

.v-center div[class*='col-'] {
  display: table-cell!important;
  vertical-align:middle;
  float:none;
}
.v-center img {max-height:125px;}

.row-low {
  max-height: 35px;
}


/* Official company colors */
.col-hygimasters {
  color: #0497C7;
}
.col-cleaningmasters {
  color: #3FA3A8;
}
.col-greenmasters {
  color: #94c23a;
}
.col-technimasters {
  color: #a43456;
}
.col-maxmasters {
  color: #ed8b00
}
.col-constructmasters {
  color: #e2d113;
}
.col-ffmasters {
  color: #485ca3;
}
.col-mobilitymasters {
  color: #ff120c;
}

/* Badges & labels with company colors */
.label-hygimasters, .badge-hygimasters {
  background-color: #0497C7;
  color: #FFFFFF;
}
.label-cleaningmasters, .badge-cleaningmasters {
  background-color: #3FA3A8;
  color: #FFFFFF;
}
.label-greenmasters, .badge-greenmasters {
  background-color: #94c23a;
  color: #FFFFFF;
}
.label-technimasters, .badge-technimasters {
  background-color: #a43456;
  color: #FFFFFF;
}
.label-maxmasters, .badge-maxmasters {
  background-color: #ed8b00;
  color: #FFFFFF;
}
.label-constructmasters, .badge-constructmasters {
  background-color: #e2d113;
  color: #FFFFFF;
}
.label-ffmasters, .badge-ffmasters {
  background-color: #485ca3;
  color: #FFFFFF;
}
.label-mobilitymasters, .badge-mobilitymasters {
  background-color: #ff120c;
  color: #FFFFFF;
}

/* Dropzone */

.dropzone, .dz-small {
  height: 100px;
}

/* Changes to Modal */
.modal-wide {
  width: 850px;
}

/* FontAwesome Spacer */
.fa-spacer {
  margin-left: 6px;
  margin-right: 6px;
}

/*
 * Pfudor edit END
 */