
/* Table stuff */
.col-crop {
  width:1%;
  white-space:nowrap;
  padding-right: 40px !important;
}
.col-crop-n {
  width:1%;
  white-space:nowrap;
  padding-right: 0px !important;
}
.col-crop-right {
  width:1%;
  white-space:nowrap;
  padding-left: 40px !important;
  text-align: right;
}
.col-right {
  text-align: right;
}
.col-no-sort::after {
  display: none!important;
}
.col-no-sort {
  pointer-events: none!important;
  cursor: default!important;
}

/* Footable custom styles */
table.footable > tbody > tr.footable-empty > td {
  font-size: 16px !important;
  color: @blue;
}

/* Select2 custom styles */
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  border-color: #e7eaec;
}
.pf-select2-yellowborder {
  border: 1px solid @yellow !important;
  border-radius: 4px;
}

/* AJAX Loader */
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-image: url('/img/loading-bars.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 64px 64px;
  background-color: rgba(255,255,255, 0.5);
  display: none;
}
.loaderPDF {
  display: none;
  position: absolute;
  z-index: 9999;
  background-color: rgba(255,255,255, 0.5);
  color: gray;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.loaderPDF-title {
  text-align: center;
  max-width: 500px;
  margin: 15% auto;
  padding: 20px;
}
.loaderPDF-title h1 {
  font-size: 26px;
}

/* Trumbowyg */
.trumbowyg-box {
  margin: 0 auto !important;
  min-height: 100px !important;
  .trumbowyg-editor {
    min-height: 100px !important;
  }
}
.form-group.has-error {
  .trumbowyg-box {
    border-color: #e74c3c !important;
  }
}

/* Validation */
.form-control.error {
  border-color: #e74c3c !important;
}
span.error {
  color: #aa0000;
  font-weight: normal;
  font-style: italic;
  margin-left: 10px;
}
span.error-sm {
  color: #e74c3c;
  font-weight: normal;
  font-style: italic;
  font-size: 0.8em;
}

/* Datatables Selected */
tr.selected {
  background-color: #17A588 !important;
  color: #fff !important;
}
tr.selected2 {
  background-color: #4dc4c5 !important;
  color: #fff !important;
}

/* Nestable */
.dd3-content { display: block; height: 30px; margin: 5px 0; padding: 5px 10px 5px 40px; color: #333; text-decoration: none; font-weight: bold; border: 1px solid #ccc;
  background: #fafafa;
  background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
  background:    -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
  background:         linear-gradient(top, #fafafa 0%, #eee 100%);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box; -moz-box-sizing: border-box;
}
.dd3-content:hover {
  color: #2ea8e5;
  background: #fff;
}
.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}
.dd3-item > button {
  margin-left: 30px;
}
.dd3-handle { position: absolute; margin: 0; left: 0; top: 0; cursor: pointer; width: 30px; height: 30px; text-indent: 100%; white-space: nowrap; overflow: hidden;
  border: 1px solid #aaa;
  background: #ddd;
  background: -webkit-linear-gradient(top, #ddd 0%, #bbb 100%);
  background:    -moz-linear-gradient(top, #ddd 0%, #bbb 100%);
  background:         linear-gradient(top, #ddd 0%, #bbb 100%);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dd3-handle:before {
  content: '≡';
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
}
.dd3-handle:hover {
  background: #ddd;
}

/* Ajax UploadFile */
.ajax-file-upload-statusbar {
  margin-top: 10px;
  width: 100%;
  margin-right: 10px;
  margin: 5px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 5px 5px 5px 5px
}
.ajax-file-upload-filename {
  width: 100%;
  height: auto;
  margin: 0 5px 5px 10px;
  color: #807579
}
.ajax-file-upload-progress {
  margin: 0 10px 5px 10px;
  position: relative;
  width: 90%;
  border: 1px solid #ddd;
  padding: 1px;
  border-radius: 3px;
  display: inline-block
}
.ajax-file-upload-bar {
  background-color: #0ba1b5;
  width: 0;
  height: 20px;
  border-radius: 3px;
  color:#FFFFFF;
}
.ajax-file-upload-percent {
  position: absolute;
  display: inline-block;
  top: 3px;
  left: 48%
}
.ajax-file-upload-red {
  -moz-box-shadow: inset 0 39px 0 -24px #e67a73;
  -webkit-box-shadow: inset 0 39px 0 -24px #e67a73;
  box-shadow: inset 0 39px 0 -24px #e67a73;
  background-color: #e4685d;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  font-family: arial;
  font-size: 13px;
  font-weight: normal;
  padding: 4px 15px;
  text-decoration: none;
  text-shadow: 0 1px 0 #b23e35;
  cursor: pointer;
  vertical-align: top;
  margin-right:5px;
}
.ajax-file-upload-green {
  background-color: #77b55a;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  display: inline-block;
  color: #fff;
  font-family: arial;
  font-size: 13px;
  font-weight: normal;
  padding: 4px 15px;
  text-decoration: none;
  cursor: pointer;
  text-shadow: 0 1px 0 #5b8a3c;
  vertical-align: top;
  margin-right:5px;
}
.ajax-file-upload {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 20px;
  cursor:pointer;
  line-height:20px;
  height:25px;
  margin:0 10px 10px 0;
  display: inline-block;
  background: #fff;
  border: 1px solid #e8e8e8;
  color: #888;
  text-decoration: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -moz-box-shadow: 0 2px 0 0 #e8e8e8;
  -webkit-box-shadow: 0 2px 0 0 #e8e8e8;
  box-shadow: 0 2px 0 0 #e8e8e8;
  padding: 6px 10px 4px 10px;
  color: #fff;
  background: #2f8ab9;
  border: none;
  -moz-box-shadow: 0 2px 0 0 #13648d;
  -webkit-box-shadow: 0 2px 0 0 #13648d;
  box-shadow: 0 2px 0 0 #13648d;
  vertical-align:middle;
}
.ajax-file-upload:hover {
  background: #3396c9;
  -moz-box-shadow: 0 2px 0 0 #15719f;
  -webkit-box-shadow: 0 2px 0 0 #15719f;
  box-shadow: 0 2px 0 0 #15719f;
}
.ajax-upload-dragdrop {

  border:0px dotted #A5A5C7;
  width:100%;
  color: #DADCE3;
  text-align:left;
  vertical-align:middle;
  padding:10px 10px 0px 10px;
}
.ajax-fileupload-textshizzle {
  padding-left: 10px;
}

/* HEADER Rotation */
.pf-headerrotate th {

  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

}

/* Footable v3 custom classes */
.pf-footable-nofilter .footable-filtering {
  display: none;
}

/* Others (old?) */
.ui-autocomplete {
  z-index: 215000000 !important;
}
.well {
  .ajax-upload-dragdrop {
    padding: 0 !important;
  }
}
.pf-labelcontent{
  display: inline-block !important;
}
.pf-ml-5 {
  margin-left: 5px;
}
.pf-m-0 {
  margin: 0 !important;
}
.note-editor {
  border: 1px solid #E5E6E7;
}
.pagination {
  margin-top: 0px;
  margin-bottom: 0px;
}
.dataTables_wrapper {
  padding-bottom: 0px;
}
.dropzone-tickets {
  min-height: 345px !important;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('/img/loader.gif') 50% 50% no-repeat rgba(255,255,255, 0.5);
  display: none;
}